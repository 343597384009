@import "_generated-font-mixins.scss";
.cabinet {
  position: relative;

  box-sizing: border-box;
  min-width: 40px;
  height: 80px;
  padding: 0 8px;

  text-align: center;
  vertical-align: middle;

  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;

  &__wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    &:hover {
      opacity: 0.75;
    }

    &.blocked {
      cursor: not-allowed;
      opacity: 0.4;
      filter: brightness(0);

      &.draft {
        opacity: 0.25;
      }
    }
  }

  &__text {
    position: absolute;
    top: 33px;
    right: 0;
    left: -5px;

    width: 50px;

    font-size: 10px;
    line-height: 1.1;
    color: #999;
    text-align: center;
  }

  &__icon__auto__refresh {
    width: 100%;

    &.blocked {
      cursor: not-allowed;
    }

    &::after {
      @include icon-auto-refresh;

      font-size: 27px;
      color: #aac544;
    }
  }

  // &__icon__auto__refresh__blocked {
  //   width: 100%;

  //   &::after {
  //     @include icon-auto-refresh;

  //     pointer-events: none;

  //     font-size: 27px;
  //     color: #aac544;

  //     opacity: 0.4;
  //     filter: brightness(0);
  //   }
  // }

  &__dot {
    position: absolute;
    top: 4px;
    right: 3px;

    display: block;

    width: 7px;
    height: 7px;

    background-color: #aac544;
    border-radius: 50%;
  }
}

.tooltip {
  z-index: 1;
  background-color: #000;
}

.modal {
  padding: 0 !important;
}
