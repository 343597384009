@import "_generated-font-mixins.scss";
.popup__background {
  position: fixed;
  z-index: 1001;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background-color: rgb(0 0 0 / 75%);
}

.popup__content {
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;

  box-sizing: border-box;
  width: 450px;
  margin: -150px 0 0 -225px;
  padding: 20px;

  background: #fff;
  border-radius: 10px;

  &.mobile {
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0;
  }

  &__title {
    margin-bottom: 20px;

    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #333;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__input {
    &__text {
      resize: none;

      width: 100%;
      height: 97px;
      padding: 8px;

      font-size: 13px;
      color: #555;

      border-radius: 4px;

      &,
      &:focus {
        border: 1px solid #a2acac;
      }

      &:hover {
        border-color: #a2acac;
      }
    }

    &__count {
      position: relative;

      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      height: 15px;

      font-size: 12px;
      color: #333;
      text-align: right;
    }
  }

  &__buttons {
    margin-top: 5px;

    &__wrapper {
      display: flex;
      gap: 21px;
      align-items: center;
    }

    &__send {
      cursor: pointer;

      padding: 7px 20px;

      font-size: 14px;
      line-height: 1;
      color: #fff;

      background: #315dbb;
      border-radius: 4px;

      &:hover {
        opacity: 0.6;
      }
    }

    &__cancel {
      color: #0058c5;
      border-bottom: 1px dotted #0058c5;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.close__button {
  position: absolute;
  top: 3px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;

  opacity: 0.65;

  &::before {
    @include icon-close;

    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 35px;
    height: 35px;

    font-size: 35px;
    line-height: 52px;
    color: #333;
  }
}

.error {
  position: absolute;
  right: 28px;
  bottom: 73px;

  font-size: 14px;
  color: #ef4926;
}
