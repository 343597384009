@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;
}

.categories__button {
  margin-right: 15px;
  padding: 3px 10px;

  font-size: 15px;
  color: #555;

  border-radius: 4px;

  &::before {
    @include icon-bars;

    margin-right: 10px;
    font-size: 12px;
  }

  &:hover {
    background: #fff;
  }

  &.active {
    background: #fff;
  }
}

.categories__dropdown {
  position: absolute;
  z-index: 9999;
  top: 41px;

  box-sizing: border-box;
  width: 1000px;
  height: 735px;

  background: #fff;
  border: 1px solid #f6f6f8;
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgb(51 51 51 / 25%);

  &::before {
    content: '';

    position: absolute;
    top: -20px;
    left: 30px;

    border: 11px solid transparent;
    border-bottom-color: #fff;
  }
}
