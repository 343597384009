@import "_generated-font-mixins.scss";
.cabinet {
  position: relative;

  box-sizing: border-box;
  min-width: 40px;
  height: 80px;
  padding: 0 8px;

  text-align: center;
  vertical-align: middle;

  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;

  &__wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    &:hover {
      opacity: 0.75;
    }

    &.blocked__cursor {
      filter: brightness(0);
    }
  }

  &__icon__refresh {
    width: 100%;

    &.needpay {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.blocked {
      &:not(.needpay) {
        cursor: not-allowed;
        opacity: 0.4;
        filter: brightness(0);

        &.draft {
          opacity: 0.25;
        }
      }
    }

    &::after {
      @include icon-highlight;

      font-size: 27px;
      color: #e86237;
    }

    &__dot {
      position: absolute;
      top: 3px;
      right: 3px;

      width: 7px;
      height: 7px;

      background: #73b222;
      border-radius: 100%;
    }
  }
}

.tooltip {
  z-index: 1;
  background-color: #000;
}
