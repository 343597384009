@import "_generated-font-mixins.scss";
.cabinet {
  position: relative;

  box-sizing: border-box;
  min-width: 40px;
  height: 80px;
  padding: 0 8px;

  text-align: center;
  vertical-align: middle;

  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;

  &__wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    &:hover {
      opacity: 0.75;
    }

    &.blocked__cursor {
      cursor: not-allowed;
    }
  }

  &__icon__history {
    cursor: pointer;
    width: 40px;
    height: 40px;

    &::after {
      @include icon-history;

      font-size: 27px;
      color: #68567a;
    }
  }

  &__text {
    position: absolute;
    top: 33px;
    right: 0;
    left: 0;

    width: 40px;

    font-size: 11px;
    line-height: 1.1;
    color: #999;
    text-align: center;
  }
}

.tooltip {
  z-index: 1;
  background-color: #000;
}

.blocked__link {
  pointer-events: none;
  opacity: 0.4;
  filter: brightness(0);
}
