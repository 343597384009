@import "_generated-font-mixins.scss";
.container {
  width: 1000px;
  margin: 0 auto;

  &.loading {
    position: relative;

    &::after {
      content: '';

      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;

      display: block;

      width: 100%;
      height: 100%;

      background: rgb(255 255 255 / 40%);
    }
  }
}
