@import "_generated-font-mixins.scss";
.owner {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;

  &__info {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  &__login {
    overflow: hidden;
    display: inline-block;

    max-width: 198px;

    font-size: 15px;
    line-height: 17px;
    color: #005cc3;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      color: #c01;
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
    background-size: contain;

    &.basic {
      background-image: url('/static/images/basic-tariff.svg');
    }

    &.pro {
      background-image: url('/static/images/pro-tariff.svg');
    }

    &.premium {
      background-image: url('/static/images/premium-tariff.svg');
    }

    &__verified {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      &::before {
        @include icon-verified-user;

        font-size: 14px;
        color: #5ea60f;
      }
    }
  }

  &__register__date {
    display: block;

    margin-top: 2px;

    font-size: 12px;
    line-height: 14px;
    color: #999;
  }
}

.tooltip {
  font-size: 14px !important;
  text-transform: capitalize;
}

.rating {
  display: flex;
  align-items: center;

  &__star {
    &::before {
      @include icon-star-fill;

      margin-right: 3px;
      font-size: 13px;
      color: #ffaf27;
    }
  }

  &__count {
    font-size: 15px;
    color: #ffaf27;
  }
}
