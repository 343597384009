@import "_generated-font-mixins.scss";
.search {
  position: relative;
  flex: 1 1 auto;

  &__form {
    position: relative;

    &__icon {
      &::before {
        @include icon-search;

        position: absolute;
        left: 13px;
        font-size: 20px;
        color: #555;
      }
    }

    &__submit {
      position: absolute;
      top: 1px;
      right: 1px;

      width: 55px;
      height: 27px;

      font-size: 0;
      font-weight: 400;

      background: #f7f8fa;
      border-radius: 0 2px 2px 0;

      &:hover {
        cursor: pointer;
      }

      &::after {
        @include icon-search;

        font-size: 17px;
        color: #999;
      }
    }

    &__reset {
      cursor: pointer;

      position: absolute;
      top: 3px;
      right: 63px;

      padding: 0 3px;

      transition: all 0.5s ease;

      &::after {
        @include icon-search-close;

        font-size: 12px;
        font-weight: 100;
      }
    }

    &__input {
      position: relative;

      box-sizing: border-box;
      width: 100%;
      height: 29px;
      padding: 5px 110px 5px 10px;

      font-size: 15px;

      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 3px;

      &:focus-visible {
        border: 1px solid #006bb8;

        & + .search__form__submit {
          background: #006bb8;

          &::after {
            color: #fff;
          }
        }
      }
    }
  }

  &.mobile {
    & .search__form {
      position: relative;
      padding: 0 12px 12px;

      &::after {
        @include icon_search;

        position: absolute;
        top: 11px;
        left: 26px;

        font-size: 14px;
        color: #555;
      }
    }

    & .search__form__input {
      height: 43px;
      padding: 12px 12px 12px 38px;
      padding-left: 35px;

      color: #333;

      background: #fff;
      border-radius: 6px;

      &:focus-visible {
        border: 1px solid #ccc;
      }
    }

    & .search__form__reset {
      top: 10px;
      right: 26px;
    }
  }
}
