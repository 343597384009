@import "_generated-font-mixins.scss";
.favorites__filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  & > div:nth-child(2) {
    flex: 0 1 578px;
  }
}
