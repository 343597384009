@import "_generated-font-mixins.scss";
.settings {
  &__layout {
    position: fixed;
    z-index: 995;
    top: 51px;
    bottom: 51px;

    width: 100%;
  }
}

.user__control {
  position: absolute;
  z-index: 996;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);

  width: calc(100% - 20px);

  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgb(0 0 0 / 30%);

  &.header {
    top: 63px;
  }

  &.cabinet {
    top: auto;
    width: calc(100% - 8px);
  }

  &::after {
    content: '';

    position: absolute;
    top: -9px;
    right: 9px;

    width: 0;
    height: 0;

    filter: drop-shadow(0 -2px 2px rgb(0 0 0 / 15%));
    border-color: transparent transparent #fff;
    border-style: solid;
    border-width: 0 7px 10px;
  }

  &__item {
    display: flex;
    align-items: center;

    width: 100%;
    min-height: 44px;
    padding: 5px 10px;

    color: #333;
    text-decoration: none;

    border-top: 1px solid #e2e2e2;

    &.inactive {
      pointer-events: none;
      opacity: 0.5;
    }

    &__blocked {
      background: rgb(0 0 0 / 7%);
    }
  }
}

.disabled {
  & div {
    display: flex;
    align-items: center;

    width: 95%;

    line-height: 1.1;
    color: #636262;
    text-align: left;
  }
}

.basic {
  background: #e7f0ff;
}

.lite {
  background: #ffe8be;
}

.plus {
  background: #e0e793;
}

.turbo {
  background: #ffd3d3;
}

.link {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  width: 100%;

  line-height: 24px;
  color: #000;

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    height: inherit;

    & i {
      position: absolute;
      color: #999;
    }

    &__package {
      &__name {
        float: right;

        margin-top: -1px;
        padding: 5px 12px 3.12px;

        font-size: 10px;
        font-weight: 400;
        font-style: normal;
        line-height: 11px;
        color: #333;
        text-transform: uppercase;

        border-radius: 4px;
      }
    }

    &__title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      padding-left: 40px;

      font-size: 15px;
      color: #333;

      border-radius: 4px;

      &__row {
        display: flex;
        align-items: center;
      }

      &__sub {
        overflow: hidden;
        display: block;
        display: -webkit-box;

        max-width: 300px;

        font-size: 12px;
        line-height: 14px;
        color: #000;

        opacity: 0.4;
      }

      &__label {
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 30px;
        height: 16px;
        margin-left: 5px;

        border-radius: 4px;

        &.label__new {
          font-size: 8px;
          color: #fff;
          background-color: #f08019;
        }

        &.label__on {
          top: 6px;

          font-size: 10px;
          line-height: 11px;
          color: #649c1e;

          background: rgb(100 156 30 / 20%);
        }
      }
    }
  }
}

.autorefresh {
  left: 2px;
  font-size: 27px;
}

.booster {
  left: 2px;
  font-size: 34px;
}

.highlight {
  left: 8px;
  font-size: 22px;
}

.label {
  left: 9px;
  font-size: 23px;
}

.widecard {
  left: -3px;
  font-size: 23px;
}

.republish {
  left: 1px;
  font-size: 39px;
}

.package {
  // top: 6px;
  left: 8px;
  font-size: 23px;
}

.accept {
  left: 8px;
  font-size: 21px;
}

.reject {
  left: 7px;
  font-size: 20px;
  color: #ef4926 !important;
}

.hide {
  left: 1px;
  font-size: 36px;
}

.edit {
  left: 1px;
  font-size: 38px;
}

.print {
  left: -1px;
  font-size: 26px;
}

.actions {
  left: 8px;
  font-size: 23px;
}

.remove {
  left: 12px;
  font-size: 20px;
}

.statistics {
  left: 8px;
  font-size: 26px;
}

.weekday {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -3px;
}

.bargaining {
  left: 9px;
  font-size: 22px;
}

.accepted {
  left: 9px;
  font-size: 22px;
  color: #ef4926 !important;
}

.eye {
  left: 10px;
  font-size: 15px;
  color: #f7c874 !important;
}

.key {
  left: 9px;
  font-size: 24px;
  color: #9328ff !important;
}

.calendar {
  left: 8px;
  font-size: 22px;
}

.block {
  left: 6px;

  width: 32px;
  height: 50px;

  opacity: 0.7;
  background-image: url('/static/images/blocked_eye.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.modal {
  padding: 0 !important;
}
