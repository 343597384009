@import "_generated-font-mixins.scss";
.container {
  position: relative;

  display: inline-block;

  max-width: 721px;
  height: 100%;
  padding-bottom: 100px;
  padding-left: 4px;

  vertical-align: top;

  &__title {
    font-size: 24px;
    line-height: 28px;
    color: #333;

    &::before {
      content: '';

      display: inline-block;

      width: 8px;
      height: 8px;
      margin-right: 10px;
      margin-bottom: 4px;

      background: #0079ff;
      border-radius: 50%;
    }
  }

  &__content {
    margin-left: 18px;

    &__title {
      margin: 20px 0 15px;

      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      color: #333;
    }

    &__description {
      & > li {
        padding: 8px 0;
        font-size: 15px;
        line-height: 17px;
        color: #333;

        &:nth-child(1) {
          padding-bottom: 16px;
        }
      }
    }

    &__button {
      cursor: pointer;

      margin: 30px 0 45px;
      padding: 8px 50px;

      line-height: 17px;
      color: #fff;

      background: #0079ff;
      border-radius: 3px;
    }

    &__image {
      max-width: 570px;
      border-radius: 4px;
      box-shadow: 0 0 10px 5px rgb(0 0 0 / 11%);
    }
  }
}
