@import "_generated-font-mixins.scss";
.header {
  display: flex;
  font-size: 0;
  border-bottom: 1px solid #e2e2e2;

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    margin-top: 2px;
  }

  &__choose {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 46px;

    font-size: 15px;
    text-align: center;

    border-bottom: 4px solid transparent;

    & span {
      display: inline-block;
      width: auto;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__choose__active {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 46px;

    font-size: 14px;
    text-align: center;

    border-bottom: 4px solid #2d82eb;

    & span {
      font-weight: 700;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 16px;
    height: 16px;
    margin-right: 5px;

    &.icon__views::after {
      @include icon-views;

      font-size: 16px;
      color: #666;
    }

    &.icon__search::after {
      @include icon-search;

      font-size: 16px;
      color: #666;
    }

    &.icon__events::after {
      @include icon-history-actions;

      font-size: 12px;
      color: #666;
    }
  }
}
