@import "_generated-font-mixins.scss";
.wrapper {
  position: fixed;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;

  background-color: #ffff;
  border-bottom: 1px solid #f0f1f4;
}

.header {
  width: 100%;

  &__logo {
    flex: 0 0 73px;

    & img {
      height: 41px;

      &:active {
        transform: translateY(1px);
      }
    }
  }

  &__top {
    &__section {
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;

      width: 1000px;
      min-height: 56px;
      margin: 0 auto;
    }
  }

  &__regions {
    margin: 0 16px;
  }

  &__info_now {
    overflow: hidden;
    display: inline-block;

    max-width: 400px;
    height: 15px;

    font-size: 13px;
    font-weight: 700;
    line-height: 15px;
    color: #005cc3;
    text-overflow: ellipsis;
    letter-spacing: 0;
    white-space: nowrap;

    &.large {
      max-width: 580px;
    }

    &:hover {
      color: #c01;
    }
  }
}

.login {
  display: flex;
  gap: 5px;
  align-items: center;

  margin-left: auto;

  font-size: 14px;
  line-height: 1;
  letter-spacing: 0;

  &__button {
    padding: 8px 16px;

    font-weight: 400;
    color: #1c1c1c;

    background-color: #f2f2f6;
    border: 1px solid #e3e3e8;
    border-radius: 10px;
  }
}
