@import "_generated-font-mixins.scss";
.overlay {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;

  &__wrapper {
    position: absolute;
    z-index: 999;
    top: 15px;
    right: 15px;

    display: flex;

    transition: transform 0.1s;

    &.visible {
      z-index: inherit;
      transform: translateX(-236px);
    }
  }

  &__button {
    cursor: pointer;

    overflow: hidden;
    display: flex;
    gap: 6px;
    align-items: center;

    height: 36px;
    padding: 10px 15px 10px 14px;

    font-size: 14px;
    line-height: 1.2;
    color: #2677dc;
    text-overflow: ellipsis;
    white-space: nowrap;

    background-color: #fff;
    border-radius: 18px;
    box-shadow: 0 2px 7px 0 rgb(0 0 0 / 18%);

    &.visible {
      z-index: inherit;
      padding: 20px 20px 20px 12px;
      font-size: 0;
    }

    &.active {
      color: #fff;
      background-color: #2677dc;

      & > i::before {
        color: #fff;
      }
    }

    &:nth-of-type(2) {
      margin-left: 20px;
    }
  }

  &__content {
    position: relative;
    z-index: -1;
    transform: translateX(150px);

    overflow: auto;

    max-height: 751px;
    padding: 0 0 1px 12px;

    opacity: 0;
    background: #fff;

    transition: transform 0.1s ease;

    &.visible {
      scrollbar-width: none; /* Firefox */

      z-index: 9999;
      transform: translateX(0);

      opacity: 1;

      -ms-overflow-style: none; /* IE and Edge */
    }

    &.visible::-webkit-scrollbar {
      display: none;
    }
  }
}

.icon {
  &__filters {
    &::before {
      @include icon-filter-btn;

      font-size: 14px;
      color: #2677dc;
    }
  }

  &__draw {
    &::before {
      @include icon-pencil-edit;

      font-size: 14px;
      color: #2677dc;
    }
  }

  &__close {
    &::before {
      @include icon-cancel;

      font-size: 15px;
      color: #2677dc;
    }
  }
}
