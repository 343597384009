@import "_generated-font-mixins.scss";
.favorite {
  display: none;
}

.wrapper {
  position: relative;

  overflow: hidden;

  width: 233px;
  min-width: 233px;
  height: initial;
  min-height: 240px;
  margin: 0 20px 20px 0;

  text-align: left;

  border: 0;

  &.large {
    width: 100%;
  }

  &:hover .favorite {
    display: inline;
  }

  &__skeleton {
    & .price {
      display: inline-block;

      max-height: 68px;
      margin-right: 7px;

      font-size: 13px;
      font-weight: 700;
      line-height: 15px;
      color: #333;
      white-space: nowrap;

      background-color: #dedede;
      border-radius: 4px;
    }
  }
}

.info {
  &__skelet {
    background-color: #dedede;
    border-radius: 4px;
  }

  &__link {
    overflow: hidden;
    display: inline-block;

    max-height: 68px;
    margin: 8px 0 5px;

    font-size: 13px;
    line-height: 17px;
    color: #005cc3;
    text-align: left;
    text-wrap: pretty;

    &:hover {
      color: #c01;
    }

    &:visited {
      color: #9330b3;
    }

    &.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.image {
  position: relative;

  overflow: hidden;
  display: block;

  width: 233px;
  height: 178px;

  border-radius: 4px;

  &__skeleton {
    width: 100%;
    height: 100%;
    background-color: #dedede;
  }

  &.large {
    width: 100%;
    height: 266px;

    & img {
      width: 100%;
      height: 266px;
      object-fit: cover;
    }
  }

  & img {
    height: 178px;
  }

  & .gradient {
    &::after {
      content: '';

      position: absolute;
      inset: 0;

      background: url('/static/images/gradient-frame.svg') center center no-repeat;
      background-size: 100% 100%;
    }
  }
}

.favorites {
  &__empty {
    cursor: pointer;

    position: absolute;
    z-index: 40;
    top: 0;
    right: 2px;

    &::before {
      content: '';

      position: absolute;
      z-index: -1;
      top: -33px;
      right: -35px;
      transform: rotate(45deg);

      width: 60px;
      height: 60px;

      background-color: #fff;
    }

    &::after {
      @include icon-heart;

      font-size: 13px;
      color: #c2c2c2;
    }
  }

  &__fill {
    cursor: pointer;

    position: absolute;
    z-index: 40;
    top: 0;
    right: 2px;

    &::before {
      content: '';

      position: absolute;
      z-index: -1;
      top: -33px;
      right: -35px;
      transform: rotate(45deg);

      width: 60px;
      height: 60px;

      background-color: #fff;
    }

    &::after {
      @include icon-fill-heart;

      font-size: 13px;
      color: #ef4726;
    }
  }
}

.booster {
  position: absolute;
  top: 8px;
  left: 8px;
}
