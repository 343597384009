@import "_generated-font-mixins.scss";
.popup__content {
  z-index: 9;

  display: flex;
  flex-direction: row;
  justify-content: center;

  box-sizing: border-box;
  width: 450px;
  padding: 97px 40px;

  font-size: 20px;
  color: #4f7422;
  text-align: center;

  background: #fff;
  border-radius: 10px;

  &.mobile {
    transform: translate(-50%, -50%);
    width: 90%;
    margin: 0;
  }

  &__text {
    margin-left: 17px;

    &__error {
      color: #c01;
    }
  }

  &__complete {
    &::before {
      @include icon-check;

      width: 18px;
      margin-top: -5px;
      margin-right: -3px;

      font-size: 21px;
      color: #4f7422;
    }
  }
}

.close__button {
  &::before {
    @include icon-close;

    cursor: pointer;

    position: absolute;
    top: -5px;
    right: 10px;

    width: 28px;

    font-size: 35px;
    color: #333;

    opacity: 0.65;
  }

  &.mobile {
    &::before {
      top: 10px;
    }
  }
}
