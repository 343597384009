@import "_generated-font-mixins.scss";
.recommended {
  &__item {
    cursor: pointer;

    position: relative;

    width: 140px;
    height: 200px;
    margin-bottom: 3px;

    &:hover {
      opacity: 0.75;
    }

    &__image {
      position: relative;

      overflow: hidden;

      width: 100%;
      height: 105px;
      margin-bottom: 10px;

      border-radius: 4px;

      &.gradient {
        &::after {
          content: '';

          position: absolute;
          inset: 0;

          background: url('/static/images/gradient-frame.svg') center center no-repeat;
          background-size: 100% 100%;
        }
      }

      & img {
        width: 100%;
        height: 105px;
      }
    }

    &__title {
      @supports (word-break: break-word) {
        hyphens: auto;
        word-break: break-word;
      }

      @supports (-webkit-line-clamp: 2) {
        display: -webkit-box;
        -webkit-box-orient: vertical;

        -webkit-line-clamp: 2;
      }

      overflow: hidden;

      max-height: 32px;
      margin-bottom: 4px;

      font-size: 13px;
      line-height: 16px;
      hyphens: auto;
      color: #005cc3;
      word-break: break-word;
    }

    &__price {
      overflow: hidden;
      line-height: 1;

      &__value {
        overflow: hidden;

        max-height: 30px;

        font-size: 13px;
        line-height: 15px;
        color: #333;
        white-space: nowrap;
      }

      &__type {
        overflow: hidden;

        max-height: 14px;
        margin-bottom: 5px;
        margin-left: 3px;

        font-size: 12px;
        font-weight: 100;
        line-height: 14px;
        color: #b4b4b4;
        white-space: nowrap;
      }
    }

    &__mobile {
      width: 100%;

      & .recommended__item__image {
        width: auto;
        margin: 0 auto 10px;
      }

      & .recommended__item__title {
        display: block;

        max-height: 18px;

        font-size: 15px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & .recommended__item__price {
        overflow: hidden;

        font-size: 11px;
        color: #b4b4b4;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & .recommended__item__price__value {
        font-size: 15px;
        font-weight: 700;
        line-height: 16px;
        color: #333;
      }

      & .recommended__item__price__type {
        text-overflow: ellipsis;
      }
    }
  }
}

.booster {
  position: absolute;
  top: 5px;
  left: 5px;

  & [data-tooltip-id] {
    width: 19px;
    height: 18px;

    &::after {
      font-size: 18px;
    }
  }
}
