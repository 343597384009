@import "_generated-font-mixins.scss";
.label {
  margin-bottom: 8px;

  &.mobile {
    font-size: 14px;
  }

  &__item {
    display: inline-block;

    padding: 8px;

    line-height: 1;
    color: #fff;
    white-space: nowrap;

    border-radius: 4px;
  }
}
