@import "_generated-font-mixins.scss";
.categories {
  position: relative;
  width: 240px;

  &__toggle {
    overflow: hidden;
    display: block;

    width: 100%;
    height: 28px;
    padding: 0 20px 0 8px;

    font-size: 14px;
    line-height: 28px;
    color: #444;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;

    border: 1px solid #ced3d3;
    border-radius: 4px;

    &::after {
      content: '';

      position: absolute;
      top: 13px;
      right: 8px;

      display: inline-block;

      border-color: #888 transparent transparent;
      border-width: 5px 4px 0;
    }

    &.open::after {
      transform: rotate(180deg);
    }
  }

  &__list {
    position: absolute;
    z-index: 1000;
    right: 0;
    left: 0;

    overflow: hidden auto;

    max-height: 310px;

    background: #fff;
    border: unset;
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%);

    &__item {
      cursor: default;
      font-size: 15px;
      font-weight: 700;

      &:first-child {
        cursor: pointer;
        padding: 6px 10px;
        font-weight: 400;

        &.selected {
          color: #000;
          background: #ddd;
        }

        &:hover,
        &.selected:hover {
          color: #fff;
          background-color: #5897fb;
        }
      }

      & > span {
        display: block;
        padding: 6px 10px;
      }
    }
  }

  &__sublist {
    &__item {
      cursor: pointer;
      padding: 6px 0 6px 20px;
      font-weight: 400;

      & > span {
        margin-left: 5px;
        font-size: 11px;
        opacity: 0.5;
      }

      &.selected {
        color: #000;
        background: #ddd;
      }

      &.selected:hover,
      &:hover {
        color: #fff;
        background-color: #5897fb;
      }
    }
  }
}
