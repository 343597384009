@import "_generated-font-mixins.scss";
.cabinet {
  position: relative;

  box-sizing: border-box;
  min-width: 40px;
  height: 80px;
  padding: 0 8px;

  text-align: center;
  vertical-align: middle;

  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;

  &.blocked__opacity {
    opacity: 0.7;
  }

  &__info {
    width: auto;
    max-width: 260px;
    padding: 5px 10px;

    font-size: 15px;
    line-height: 17px;
    color: #333;
    text-align: left;

    border-right: solid 1px #ccc;

    &__title {
      overflow: hidden;

      max-height: 34px;
      margin-bottom: 4px;

      hyphens: auto;
      text-overflow: ellipsis;
      letter-spacing: 0;
      word-break: break-word;
      white-space: normal;

      & a:hover {
        text-decoration: underline;
      }
    }

    &__price {
      margin-bottom: 8px;

      font-size: 15px;
      font-weight: 700;
      line-height: 17px;
      color: #333;
      letter-spacing: 0;

      &__thin {
        font-weight: 300;
      }
    }

    &__breadcrumbs {
      overflow: hidden;
      display: flex;

      max-width: 100%;

      font-size: 11px;
      color: #333;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.counter {
        display: flex;
        flex-direction: row;
        gap: 8px;
      }

      & a:hover {
        text-decoration: underline;
      }

      & a:nth-child(1) > span {
        margin: 0 5px;
      }

      &__posted {
        display: flex;
        align-items: center;
        color: #999;

        &::before {
          @include icon-clock;

          margin-right: 4px;
          font-size: 10px;
          color: #999;
        }
      }

      &__status {
        overflow: hidden;

        min-width: 0;
        margin-left: 4px;

        color: #999;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__rent {
        display: flex;
        align-items: center;

        height: 20px;
        margin-top: 8px;
        padding: 18px 19px 18px 25px;

        font-size: 14px;
        color: #333;
        letter-spacing: 0.5px;

        background: #fcfcfc;
        border: 1px solid #e3e3e3;
        border-radius: 4px;

        &::before {
          @include icon-calendar-icon;

          margin-right: 10px;
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
}

.tooltip {
  z-index: 1;
  max-width: 200px;
  font-size: 14px !important;
  background-color: #000;
}
