@import "_generated-font-mixins.scss";
.loader {
  width: 30px;
  height: 30px;

  border: 3px solid #f3f3f3;
  border-top: 3px solid #3498db;
  border-radius: 50%;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.height {
  min-height: 100vh;
}
