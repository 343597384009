@import "_generated-font-mixins.scss";
.message {
  position: relative;

  & textarea {
    width: 720px;
    height: 72px;
    max-height: 150px;
    margin-top: 30px;
    padding: 10px !important;

    font-size: 15px;
    color: #000;

    border: 1px solid #ced3d3;
    border-radius: 4px;
  }

  &__btn {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__send {
      margin-left: 15px;
      font-size: 13px;
      color: #65941f;
    }
  }
}

.content {
  @media screen and (width <= 480px) {
    width: 260px;
  }

  width: 372px;

  &__title {
    margin: 0 0 20px;
    padding: 0;

    font-size: 24px;
    font-weight: 400;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;

    &__description {
      line-height: 1.4;
      color: #333;
    }
  }

  & button {
    margin: 25px 0 0;
  }

  &__action {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.error__message {
  position: absolute;
  right: 37px;
  bottom: 39px;

  font-size: 14px;
  color: #c01;
}

.modal {
  padding: 20px;
  border-radius: 8px;
}
