@import "_generated-font-mixins.scss";
@mixin radio-style {
  label {
    display: flex;
    align-items: center;

    & > input {
      width: 20px;
      height: 20px;
      margin: 0 10px 0 0;

      vertical-align: middle;

      appearance: none;
      background-color: #fff;
      border: 1px solid #b1b1b1;
      border-radius: 50%;

      &:checked {
        background-color: #fefefe;
        border: 6px solid #2384ea;
      }
    }
  }
}

.categories {
  &__toggle {
    position: absolute;
    top: -52px;
    right: 0;

    width: 52px;
    height: 51px;

    text-align: center;

    background: #ececef;

    &::after {
      @include icon_filter-btn;

      cursor: pointer;

      font-size: 20px;
      line-height: 51px;
      color: #555;
      text-align: center;
    }

    &.open::after {
      transform: rotate(180deg);
    }
  }

  &__list {
    background: #fff;
    border: unset;
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%);

    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
    }

    &__title {
      flex: 1 0 auto;

      font-size: 16px;
      font-weight: 700;
      line-height: 18px;
      color: #333;
      text-align: center;
      letter-spacing: 0;
    }

    &__close {
      width: 52px;
      height: 51px;
      text-align: center;

      &::after {
        @include icon_cancel;

        font-size: 20px;
        line-height: 52px;
        color: #6e6e6e;
      }
    }

    &__item {
      cursor: default;
      font-size: 15px;

      &:first-child {
        @include radio-style;

        cursor: pointer;

        padding: 17px 15px;

        font-size: 14px;
        line-height: 16px;
        color: #333;
        letter-spacing: 0;

        background-color: #f5f5f7;

        &.selected {
          background-color: #ddd;
        }
      }

      &:not(:first-child),
      &:not(:nth-child(2)) {
        & > span {
          position: relative;

          box-sizing: border-box;
          width: 100%;
          padding: 17px 16px;

          font-size: 15px;
          line-height: 20px;
          color: #333;
          letter-spacing: 0;

          border-bottom: 1px solid #e2e2e2;

          &::after {
            @include icon_arrow-down;

            position: absolute;
            top: 0;
            right: 0;

            padding: 23px 15px;

            font-size: 17px;
            color: #999;
          }
        }
      }

      & > span {
        display: block;
        padding: 6px 10px;
      }
    }
  }

  &__sublist {
    overflow: hidden;
    max-height: 1000px;
    transition: 0.6s all;

    &__item {
      @include radio-style;

      cursor: pointer;

      padding: 16px;

      font-size: 15px;
      font-weight: 400;

      background-color: #f5f5f7;

      & > span {
        margin-left: 5px;
        font-size: 11px;
        opacity: 0.5;
      }

      &.selected {
        background-color: #ddd;
      }
    }
  }
}
