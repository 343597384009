@import "_generated-font-mixins.scss";
.empty {
  margin-bottom: 20px;
  text-align: center;

  & > h5 {
    margin-top: 60px;
    margin-bottom: 44px;

    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    color: rgb(60 60 60 / 30%);
    text-align: center;
  }

  & > img {
    margin: 0 auto;
  }

  & > a {
    cursor: pointer;

    display: inline-block;

    margin-top: 47px;
    padding: 9px 16px;

    font-size: 14px;
    line-height: 20px;
    color: rgb(255 255 255);

    background-color: rgb(115 178 34);
    border: 1px solid transparent;
    border-radius: 4px;
  }
}
