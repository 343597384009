@import "_generated-font-mixins.scss";
.wrapper {
  display: flex;
  font-size: 11px;
  color: #999;

  &__icon {
    margin-right: 5px;
  }
}

.views::after {
  @include icon-eye;
}

.favorites::after {
  @include icon-heart;
}

.views::after,
.favorites::after {
  font-size: 8.5px;
  color: #999;
}
