@import "_generated-font-mixins.scss";
.categories {
  display: flex;
  height: 100%;

  &__wrapper {
    box-sizing: border-box;
    width: 1000px;
    height: 735px;

    background: #fff;
    border: 1px solid #f6f6f8;
    border-radius: 4px;
    box-shadow: 0 4px 12px 0 rgb(51 51 51 / 25%);

    &::before {
      content: '';

      position: absolute;
      top: -20px;
      left: 30px;

      border: 11px solid transparent;
      border-bottom-color: #fff;
    }

    &.collapsed__type {
      position: relative;
      height: auto;
      border: none;
      box-shadow: none;
    }
  }
}
