@import "_generated-font-mixins.scss";
.button {
  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  height: 40px;
  padding: 0 20px;

  font-size: 15px;
  line-height: 17px;
  color: #fff;

  border-radius: 4px;

  & > i {
    margin-right: 4px;
    font-size: 15px;
    color: inherit;
  }

  &.full__width {
    width: 100%;
  }

  &.small {
    height: 29px;
    padding: 0 20px;
    font-size: 15px;
  }

  &.medium {
    height: 36px;
    padding: 0 20px;
  }

  &.large {
    height: 56px;
    padding: 0 40px;
    font-size: 20px;
    font-weight: 700;
  }

  &.type__link {
    padding: 0;

    color: #005cc3;

    background-color: transparent;
    border: none;
    border-radius: 0;

    &:hover {
      color: #c01;
    }
  }

  &.type__link__dashed {
    height: auto;
    padding: 0;
    padding-bottom: 1px;

    font-size: 12px;
    line-height: 16px;
    color: #005cc3;

    background-color: transparent;
    border: none;
    border-bottom: dotted 1px;
    border-radius: 0;

    &:hover {
      color: #c01;
    }
  }

  &.type__link__dots {
    height: 20px;
    padding: 0;
    padding-bottom: 1px;

    color: #005cc3;

    background: 0 0;
    border-bottom: dotted 1px;
    border-radius: 0;

    &:hover {
      color: #c01;
    }
  }

  &.type__link__red {
    padding: 0;

    color: #c01;

    background-color: transparent;
    border: none;
    border-radius: 0;

    &:hover {
      opacity: 0.7;
    }
  }

  &.type__link__secondary {
    padding: 0;
    color: #73b222;
    background-color: transparent;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }

  &.type__primary {
    min-width: 179px;
    background-color: #73b222;
    border: 1px solid #73b222;

    &:hover {
      filter: brightness(1.1);
    }

    &.outline {
      color: #73b222;
      background-color: transparent;
      border-color: #73b222;

      &:hover {
        color: #fff;
        background-color: #73b222;
      }
    }
  }

  &.type__primary__2 {
    min-width: 179px;
    color: #73b222;
    background-color: transparent;
    border: 1px solid #73b222;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &.type__secondary {
    background-color: #006bb8;

    &:hover {
      filter: brightness(1.1);
    }

    &.outline {
      color: #0058c5;
      background-color: transparent;
      border: 1px solid #006bb8;

      &:hover {
        background-color: #e8f1fc;
        filter: none;
        border-color: #e8f1fc;
      }
    }
  }

  &.type__secondary__3 {
    background-color: #0058c5;

    &:hover {
      filter: brightness(1.1);
    }

    &.outline {
      color: #0058c5;
      background-color: transparent;
      border: 1px solid #006bb8;

      &:hover {
        background-color: #e8f1fc;
        filter: none;
        border-color: #e8f1fc;
      }
    }
  }

  &.type__secondary__2 {
    background-color: #315dbb;
    border-color: #315dbb;

    &:hover {
      filter: brightness(1.1);
    }

    &.outline {
      color: #0058c5;
      background-color: transparent;
      border: 1px solid #006bb8;

      &:hover {
        color: #fff;
        opacity: 0.8;
        background-color: #315dbb;
        filter: none;
      }
    }
  }

  &.type__default {
    background-color: #b9b9b9;
    border: 1px solid #b9b9b9;

    &:hover {
      filter: brightness(1.1);
    }

    &.outline {
      color: #b9b9b9;
      background-color: transparent;
      border-color: #b9b9b9;

      &:hover {
        color: #fff;
        background-color: #b9b9b9;
      }
    }
  }

  &.type__secondary__red {
    background-color: #fff;
    border: 1px solid #fe0000;

    &:hover {
      filter: brightness(1.1);
    }

    &.outline {
      color: #fe0000;
      background-color: transparent;
      border-color: #fe0000;
    }
  }

  &.type__default__reverse {
    color: #333;
    background-color: #f2f2f7;
    border: 1px solid #f2f2f7;

    &:hover {
      opacity: 0.8;
    }
  }

  &.type__default__blue__text {
    padding: 15px;

    font-weight: 600;
    color: #246dc1;

    background-color: #fafafa;
    border: 1px solid #fafafa;
  }

  &.type__border__blue__text {
    padding: 15px;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #246dc1;
    text-wrap: nowrap;
    letter-spacing: 0.2px;

    background-color: #fafafa;
    border: 1px solid #005cc3;
  }

  &.type__default__white {
    padding: 15px;
    color: #000;
    background-color: #fff;
    border: 1px solid #fff;
  }

  &.type__default__white__outline {
    padding: 16px;

    color: rgb(51 51 51);

    background-color: rgb(255 255 255);
    border: 1px solid rgb(118 118 118 / 12%);
    border-radius: 4px;
  }

  &.type__added__to__cart {
    color: #333;
    background: #fff;
    border: 1px solid grey;
    border: 1px solid #ececec;

    &::before {
      @include icon-check;

      margin-right: 7px;
      font-size: 9px;
      color: #73b222;
    }
  }

  &.type__silver__border {
    color: #333;
    text-wrap: nowrap;

    background: #fff;
    border: 1px solid rgb(118 118 118 / 12%);
    border: 1px solid #ececec;
  }

  &.type__added__to__cart__mobile {
    height: 36px;
    padding: 9px 7px;

    font-size: 11px;
    color: #333;

    background: #fff;
    border: 1px solid grey;
    border: 1px solid #ececec;

    &::before {
      @include icon-check;

      margin-right: 5px;
      font-size: 8px;
      color: #73b222;
    }
  }

  &.type__add__to__cart__mobile {
    height: 36px;
    padding: 9px 7px;

    font-size: 11px;
    line-height: 16px;
    color: #fff;

    background-color: #73b222;
    border: 1px solid #73b222;

    &::before {
      @include icon-market;

      margin-right: 5px;
      font-size: 18px;
      color: #fff;
    }
  }

  &.type__package__turn__off {
    width: 200px;

    font-size: 14px;
    line-height: 16px;
    color: #ef4926;

    background: #fff;
    border: 1px solid #eee;

    &:hover {
      color: #ef4926;
      background: #fff;
    }
  }

  &.type__danger {
    background: rgb(190 25 16);
    border: 1px solid rgb(118 118 118 / 12%);

    &:hover {
      filter: brightness(1.1);
    }
  }

  &:disabled,
  &.outline:disabled,
  &.outline:disabled:hover,
  &:disabled:hover {
    cursor: default;

    color: #b9b9b9;

    background-color: #efefef;
    filter: none;
    border: #b9b9b9;
  }
}
