@import "_generated-font-mixins.scss";
.categories__list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  box-sizing: border-box;
  width: 333px;
  height: 100%;
  padding: 10px;

  box-shadow: 5px 0 25px 0 rgb(0 0 0 / 7%);

  &__item {
    cursor: pointer;
    display: flex;
    padding: 0 10px;
    font-size: 13px;

    &:hover {
      color: #005cc3;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
    margin-right: 9px;

    &::before {
      font-size: 20px;
      color: #999;
    }
  }

  &.collapsed__type {
    justify-content: flex-start;

    box-sizing: border-box;
    width: 245px;
    padding: 0;

    border: none;
    box-shadow: none;

    & .categories__list__item {
      cursor: pointer;
      display: flex;
      padding: 6px 7px;
      font-size: 14px;
    }
  }
}

.active {
  &__link {
    color: #005cc3;
  }

  &__icon {
    filter: invert(1) sepia(1) saturate(20) hue-rotate(203deg) brightness(0.8);
  }
}
