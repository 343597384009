@import "_generated-font-mixins.scss";
.block {
  width: 305px;
  font-size: 13px;
  line-height: 15px;

  &__header {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: 700;
    color: #333;
  }

  &__item {
    margin-bottom: 11px;
  }

  &__link {
    font-size: 13px;
    line-height: 16px;
    color: #333;

    &:hover {
      color: #005cc3;
    }
  }
}

.subcategories {
  &__column {
    width: 50%;
    min-width: 50%;

    &:last-child {
      padding-bottom: 20px;
    }
  }
}
