@import "_generated-font-mixins.scss";
.wrapper {
  position: relative;
  width: 1000px;
  height: 400px;
  margin: 0 auto;

  & > p {
    padding: 30px 0;
    font-weight: bold;
    line-height: 40px;
    color: #000;

    &::before {
      content: none;
    }
  }

  &__title {
    margin: 20px 0 0 15px;
    font-size: 24px;
  }

  &__content {
    margin: 15px;
    font-size: 16px;

    &__support {
      margin-top: 50px;
      font-size: 14px;
      font-style: italic;
      color: #888;
    }

    &__code {
      overflow: scroll;
      display: block;

      max-height: 160px;
      padding: 10px;

      font-size: 11px;

      background-color: #e9e9e9;
      border-radius: 4px;
    }
  }
}
