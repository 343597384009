@import "_generated-font-mixins.scss";
.carousel {
  &:not(.has__title) {
    padding: 20px 0 0;
  }

  &__container {
    width: 100%;
    height: 100%;
    min-height: 155px;
    margin: 0;
    padding-left: 10px !important;
  }

  &__item {
    width: 37% !important;
    margin-right: 10px;
  }

  &__title {
    padding: 0;
    padding: 15px 10px;

    font-size: 18px;
    font-weight: 700;
    line-height: 21px;
    color: #000;
  }
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 185px;
}
