@import "_generated-font-mixins.scss";
.buttons {
  display: flex;
  gap: 10px;

  &.mobile {
    display: block;
  }

  &__close {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}

.hide__write__block {
  pointer-events: none;
  opacity: 0.5;
}
