@import "_generated-font-mixins.scss";
.phone__container {
  display: flex;
  flex-direction: column;

  max-width: 300px;
  padding: 0 16px;

  border: 1px solid #f0f1f4;
  border-radius: 4px;
}

.phone__item {
  display: flex;
  gap: 5px;
  align-items: center;

  width: 100%;
  padding: 7px 0;

  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  color: #000;

  border-top: 1px solid #f0f1f4;

  & i {
    font-size: 24px;
    color: #cacccf;
  }
}

.phone__item:first-of-type {
  border-top: none;
}
