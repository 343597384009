@import "_generated-font-mixins.scss";
.cropper {
  &__container {
    position: absolute;
    z-index: 50;
    overflow: hidden;
    margin: 0 auto;

    &__controls {
      &__tip {
        position: absolute;
        margin-left: 15px;
        font-size: 13px;
        color: #fff;
      }

      &__buttons {
        position: absolute;
        right: 45px;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-right: -30px;

        &__cancel {
          cursor: pointer;

          margin-right: 10px;
          padding: 8px 22px;

          font-size: 13px;
          color: #333;

          background-color: rgb(255 255 255 / 60%);
          border-radius: 4px;
        }

        &__accept {
          cursor: pointer;

          padding: 8px 22px;

          font-size: 13px;
          color: #fff;

          background-color: rgb(0 0 0 / 40%);
          border: 1px solid rgb(255 255 255 / 50%);
          border-radius: 4px;

          & span {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.size__user {
  width: 740px;
  height: 274px;
}

.position__user {
  top: 25px;
  left: 0;
}

.position__buttons__user {
  top: 15px;
}
