@import "_generated-font-mixins.scss";
.rating {
  margin-top: 10px;

  &__link {
    &:hover {
      & .popup {
        visibility: visible;
      }
    }
  }

  &__block {
    position: relative;

    display: flex;
    justify-content: center;

    width: 100%;

    font-size: 13px;
    line-height: 16px;
    color: #999;

    &__icon {
      width: 14px;
      height: 14px;
      margin-top: -3px;
      margin-right: 3px;

      &::after {
        @include icon-star-fill;

        font-size: 14px;
        color: #ffaf27;
      }
    }

    &__text {
      margin-right: 5px;
      font-size: 17px;
      line-height: 17px;
      color: #f5b049;
    }

    &__counter {
      font-size: 13px;
      line-height: 14px;
      color: #315dbb;
    }
  }
}

.popup {
  position: absolute;
  z-index: 3;
  top: -55px;
  left: 95%;

  display: block;

  visibility: hidden;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 29%);

  &__wrapper {
    position: relative;

    width: 220px;
    padding: 25px;

    background-color: #fff;
    border-radius: 4px;
  }

  &__triangle {
    position: absolute;
    z-index: -1;
    top: 54px;
    left: -10px;
    transform: rotate(45deg);

    display: block;

    width: 20px;
    height: 20px;

    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 29%);
  }

  &__overall {
    display: flex;
    align-items: baseline;

    margin-bottom: 15px;
    padding-bottom: 15px;

    border-bottom: solid 1px #ebebeb;

    &__icon {
      margin-right: 5px;

      &::after {
        @include icon-star-fill;

        font-size: 20px;
        color: #ffae28;
      }
    }

    &__rating {
      margin-right: 15px;
      font-size: 24px;
      line-height: 1;
      color: #333;
    }

    &__counter {
      font-size: 13px;
      line-height: 1;
      color: #333;
    }
  }
}
