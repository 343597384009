@import "_generated-font-mixins.scss";
.content {
  @media screen and (width <= 640px) {
    width: 100%;
    padding: 20px 16px 0;
  }

  display: flex;
  flex-direction: column;

  width: 300px;
  height: auto;
  margin-bottom: 20px;

  font-size: 17px;
  line-height: 20px;
  color: #333;

  & h2 {
    padding-bottom: 20px;
  }

  & button {
    margin-top: 14px;
  }

  &__input {
    position: relative;

    overflow: hidden;
    display: flex;
    justify-content: space-between;

    height: 36px;

    font-size: 15px;

    border: 1px solid #ced3d3;
    border-radius: 4px;

    & input {
      width: 100%;
      height: 100%;
      padding: 9px 12px;

      appearance: textfield;
      border: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        margin: 0;
        appearance: none;
      }
    }

    & select {
      @media screen and (width <= 640px) {
        font-size: 13px;
        text-align-last: right;
      }

      min-width: 76px;
      height: 100%;
      padding: 0 30px 0 10px;

      appearance: none;
      border: 0;
      border-left: 1px solid #ced3d3;
    }

    &::after {
      content: '';

      position: absolute;
      top: 45%;
      right: 12px;
      transform: rotate(360deg);

      width: 0;
      height: 0;

      border-color: #333 transparent transparent;
      border-style: solid;
      border-width: 4px 4px 0;
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
