@import "_generated-font-mixins.scss";
.subcategories {
  overflow: auto;
  display: flex;
  flex: 1;
  padding: 0 20px 20px;

  &.collapsed__type {
    position: absolute;
    z-index: 3;
    left: 100%;

    overflow: auto;
    display: none;
    flex: 1 1;
    flex-wrap: wrap;

    box-sizing: border-box;
    width: 755px;
    height: 100%;
    padding: 0 20px 20px;

    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px 4px rgb(0 0 0 / 4%);
  }

  &.opened {
    display: flex;
  }
}
