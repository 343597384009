@import "_generated-font-mixins.scss";
.background {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 274px;
  margin-bottom: 30px;

  background-color: #f0f1f4;
  border-radius: 4px;

  & img {
    border-radius: 4px;
  }
}
