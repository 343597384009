@import "_generated-font-mixins.scss";
/* stylelint-disable no-descending-specificity */
.regions {
  position: relative;
  min-width: 110px;

  &__dropdown {
    position: absolute;
    z-index: 3000;
    top: -3px;
    right: 0;
    left: 0;

    display: none;

    min-width: 180px;
    padding: 10px 45px;

    font-size: 14px;
    color: #292929;

    background: #fff;
    border-radius: 6px;
    box-shadow: 10px 10px 50px rgb(0 0 0 / 15%);

    &__active {
      display: block;
    }
  }

  &__list {
    &__item {
      cursor: pointer;
      margin-bottom: 13px;
      line-height: 20px;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }

      &__active {
        cursor: default;
        font-weight: bold;

        &:hover {
          text-decoration: none;
        }
      }

      &:nth-child(3) {
        padding-top: 12px;
        border-top: solid 1px rgb(0 0 0 / 10%);
      }
    }
  }

  &__toggle {
    cursor: pointer;

    font-size: 12px;
    line-height: 1.3;
    color: #292929;
    letter-spacing: 0;

    & > span {
      border-bottom: dotted 1px #a4a5a6;
    }

    &:hover {
      & > span {
        border-bottom-style: solid;
      }
    }
  }

  &__close {
    cursor: pointer;
    margin-bottom: 22px;
    font-size: 12px;
    color: #292929;

    & > span {
      padding-bottom: 3px;
      border-bottom: dotted 1px #a4a5a6;
    }

    &:hover {
      & > span {
        border-bottom-style: solid;
      }
    }
  }
}

.ads__count {
  min-width: 141px;
  font-size: 11px;
  color: #999;
  white-space: nowrap;
}

.backdrop {
  &::after {
    content: '';

    position: fixed;
    z-index: 2000;
    inset: 0;

    display: block;

    width: 100%;
    height: 100vh;

    background: rgb(0 0 0 / 20%);
  }
}
