@import "_generated-font-mixins.scss";
.favorite {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  color: #0058c5;

  &:hover {
    & .favorite__text {
      color: #c01;
    }
  }
}

.heart {
  &::before {
    @include icon-heart;

    font-size: 15px;
    color: #999;
  }
}

.fill__heart {
  &::before {
    @include icon-fill-heart;

    font-size: 15px;
    color: #ef4826;
  }
}
