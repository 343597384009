@import "_generated-font-mixins.scss";
.upload {
  display: none;
  margin-top: 15px;

  &.allowed {
    display: block;
  }

  & input {
    display: none;
  }

  &__title {
    cursor: pointer;

    display: flex;
    gap: 10px;
    align-items: center;

    color: #999;

    &::before {
      @include icon-paperclip;

      font-size: 17px;
      line-height: 1;
      color: inherit;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 16px -4px 0;
    margin-top: 15px;
  }
}

.figure {
  position: relative;

  overflow: hidden;
  display: inline-block;

  margin: 0 4px 10px;
  padding: 0;

  vertical-align: top;
}

.image {
  position: relative;

  max-width: 94px;
  max-height: 94px;

  text-align: left;

  object-fit: contain;
  border-radius: 2px;
}

.remove {
  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;

  opacity: 0.8;
  background-color: #333;
  border-top-right-radius: 2px;
  border-bottom-left-radius: 2px;

  &::before {
    @include icon-trash;

    font-size: 13px;
    line-height: 1;
    color: #fff;
  }
}

.doc {
  display: block;

  width: 94px;
  height: 94px;

  background-image: url('/static/doc.svg');
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  border-radius: 2px;
}

.fade {
  animation: fadeout 0.2s linear forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.content {
  @media screen and (width <= 640px) {
    padding: 24px 16px;
  }

  border-radius: 5px;

  & h2 {
    @media screen and (width <= 640px) {
      margin-bottom: 24px;
      font-size: 20px;
      font-weight: 700;
      color: #555;
    }

    font-size: 18px;
    font-weight: bold;
  }

  &__button {
    @media screen and (width <= 640px) {
      position: fixed;
      z-index: 1001;
      bottom: 0;
      left: 0;

      box-sizing: border-box;
      width: 100%;
      margin: 0;
      padding: 10px 16px 15px;

      background: #f5f5f5;
    }
  }
}

.radio__group {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  & li {
    &:not(:first-of-type) {
      @media screen and (width <= 640px) {
        margin-top: 0;
      }

      margin-top: 6px;
    }
  }

  & label {
    @media screen and (width <= 640px) {
      margin-bottom: 24px;
      font-size: 15px;
      line-height: 17px;
    }

    display: flex;
    align-items: center;
    font-size: 13px;
    color: #333;

    & input[type='radio'] {
      @media screen and (width <= 640px) {
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
      }

      margin-right: 10px;
    }
  }
}

.description {
  @media screen and (width <= 640px) {
    position: relative;
    padding-bottom: 20px;
  }

  &__textarea {
    @media screen and (width <= 640px) {
      margin-top: 0;
      padding: 10px;
      background: #fff;
      border: 1px solid #e2e2e2;
    }

    resize: none;

    width: 100%;
    height: 100px;
    margin-top: 20px;
    padding: 7px 12px;

    font-size: 15px;
    vertical-align: top;

    border: 1px solid #ced3d3;
    border-radius: 4px;

    &:focus {
      border: 1px solid #b2b2b2;
    }

    &:required {
      border: 1px solid #ea3636;
    }
  }

  &__char__count {
    position: absolute;
    right: 20px;
    bottom: 45px;

    font-size: 11px;
    font-style: normal;
    line-height: 13px;
    color: #555;
  }

  &__char__count__mobile {
    position: absolute;
    right: 0;
    bottom: 0;

    font-size: 11px;
    font-style: normal;
    line-height: 13px;
    color: #555;
  }
}

.button {
  margin-top: 15px;
  padding: 8px 20px;

  font-size: 15px;
  line-height: 1;
  color: #fff;
  letter-spacing: -0.42px;

  opacity: 1;
  background: #0058c5;
  border: none;
  border-radius: 4px;

  &:not(:disabled) {
    &:hover {
      opacity: 0.9;
      background: #0056b3;
      transition: opacity 0.2s;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background: #ccc;
  }
}

.complaint__success {
  @media screen and (width <= 640px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    min-height: 70vh;
  }

  padding: 80px 0;
  color: #000;
  text-align: center;

  &__header {
    @media screen and (width <= 640px) {
      padding-top: 83px;
      font-size: 20px;
      color: #437607;
      letter-spacing: 0;
    }

    display: flex;
    flex-direction: column;

    margin-bottom: 8px;

    font-size: 24px;
    color: #88b44f;
    letter-spacing: -0.67px;

    &::before {
      @include icon-success;

      font-size: 80px;
      color: #88b44f;
    }
  }

  &__text {
    max-width: 350px;
    margin: 0 auto;
    padding: 0 30px;

    font-size: 15px;
    line-height: 21px;
    color: #333;
  }

  &__close {
    position: absolute;
    z-index: 1001;
    top: 0;
    left: 0;
  }

  &__footer {
    position: fixed;
    z-index: 1001;
    bottom: 0;
    left: 0;

    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 10px 16px 14px;

    background: #f5f5f5;
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.close__icon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 52px;
  height: 51px;

  background-color: #ececef;

  &::before {
    @include icon-cancel;

    font-size: 16px;
    color: #555;
  }
}

.sended {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &__text {
    padding: 40px 20px;

    font-size: 16px;
    line-height: 21px;
    color: #333;
    text-align: center;
  }
}
