@import "_generated-font-mixins.scss";
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  min-width: 350px;
  min-height: 40vh;

  background-color: #fff;

  &.mobile {
    width: 100%;
    height: calc(100vh - 51px);
  }
}
