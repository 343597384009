@import "_generated-font-mixins.scss";
.statistics {
  &__modal {
    box-sizing: content-box;
    width: 730px;
    margin: -10px;
  }

  &__title {
    width: 90%;
    font-size: 24px;
    font-weight: 700;
    word-wrap: break-word;
  }

  &__date {
    display: flex;
    align-items: center;

    font-size: 12px;
    line-height: 14px;
    color: #666;

    background: #fff;

    &__left {
      gap: 3px;

      height: 18px;
      margin-left: 15px;
      padding: 2px 10px;

      font-size: 12px;

      border-radius: 28px;
    }
  }

  &__tabs {
    display: flex;
    margin-top: 22px;
    background: #fff;
    border-bottom: 1px solid #efefef;

    &__toggle {
      margin-right: 20px;
      padding: 8px 0;

      font-size: 14px;
      line-height: 14px;
      color: #333;
      white-space: nowrap;

      border-bottom: 3px solid transparent;

      &.active {
        color: #005cc3;
        border-bottom-color: #005cc3;
      }

      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  &__views {
    flex: 1 0 auto;
    margin-top: 20px;
    background: #f5f6f9;
    border-radius: 4px;

    &:not(:first-child) {
      margin-left: 15px;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
    }

    &__title {
      display: flex;
      align-items: center;

      padding: 10px 0 0 10px;

      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      color: #000;

      & .tooltip {
        width: 270px;
        padding: 10px;

        font-size: 11px;
        font-weight: 400;
        line-height: 13px;

        background: #363d47;
        border-radius: 4px;
      }

      & > .tooltip__icon::after {
        @include icon_question;

        margin-left: 3px;
        font-size: 14px;
      }
    }

    &__inner {
      display: inline-flex;
      padding: 10px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      height: 43px;

      &:not(:last-child) {
        padding-right: 20px;
        border-right: 1px solid #e1e4ed;
      }

      &:not(:first-child) {
        padding-left: 20px;
      }

      & > span {
        font-size: 11px;
        font-weight: 400;
        line-height: 12.65px;

        &:first-child {
          font-size: 25px;
          font-weight: 400;
          line-height: 28.75px;
        }

        & > .mdl {
          margin-left: 6px;
          font-size: 18px;
        }
      }
    }

    &__chart {
      position: relative;
      background: #fff;

      &__period {
        position: absolute;
        top: 5px;
        left: 0;

        font-size: 13px;
        font-weight: 700;
        line-height: 14.95px;
      }
    }
  }

  &__presets {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px 0 10px;

    background: #fff;

    &__title {
      font-size: 15px;
      font-weight: 700;
      line-height: 17.25px;
    }

    &__group {
      background: #f5f6f9;
      border: 1px solid #eaecf0;
      border-radius: 5px 0 0 5px;
    }

    &__btn {
      gap: 14px;

      box-sizing: border-box;
      padding: 5px 10px;

      font-size: 11px;
      line-height: 13px;

      border: 1px solid transparent;
      border-radius: 5px;

      &.active {
        background: #fff;
        border: 1px solid #eaecf0;
      }
    }
  }

  &__booster {
    &__status {
      display: flex;
      flex: 1 0 auto;
      align-items: center;
      justify-content: center;

      height: 90px;
      margin: 20px 0 0 15px;
      padding: 10px;

      background: #f5f6f9;
      border-radius: 4px;

      & > div {
        margin-top: 10px;
        font-size: 12px;
        line-height: 14px;
      }

      & > a {
        display: block;
        font-size: 11px;
        line-height: 12px;
        color: #005cc3;
      }
    }
  }

  &__mobile {
    background: #f5f5f5;

    & .statistics__title {
      padding: 0 15px 15px;
      font-size: 17px;
      line-height: 16px;
      background: #fff;
    }

    & .statistics__date {
      flex-direction: column;
      align-items: flex-start;

      padding: 0 16px 16px;

      font-size: 14px;
      line-height: 16px;

      &__left {
        margin: 10px 0 0;
      }
    }

    & .statistics__tabs {
      overflow-x: scroll;
      margin: 0;
      border-top: 1px solid #d7d7d7;
      border-bottom: 1px solid #d7d7d7;

      &__toggle {
        padding: 15px 10px;
        font-size: 15px;
      }
    }

    & .statistics__views {
      margin: 15px 10px 15px 0;
      background: #fff;

      &__wrap {
        overflow-x: scroll;
        width: auto;
        padding: 0 0 0 10px;
      }

      &__title {
        padding: 16px 10px;
        font-size: 17px;
        border-bottom: 1px solid #d7d7d7;
      }

      &__item {
        & > span:last-child {
          overflow: hidden;
          max-width: 97px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      & .tooltip__icon {
        display: none;
      }
    }

    & .statistics__presets {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
      padding: 15px;

      &__title {
        margin-bottom: 10px;
      }
    }

    & .statistics__views__chart {
      padding: 10px 15px;

      &__mobile {
        padding: 0;
      }

      &__period {
        position: static;
        font-size: 13px;
      }
    }

    & .statistics__booster {
      &__status {
        flex: 0 0 auto;
        height: 124px;
        margin: 15px 0 15px 10px;
        background: #fff;

        & > div {
          font-size: 14px;
        }

        & > a {
          font-size: 12px;
        }
      }

      &__table {
        overflow: scroll visible;

        &__body {
          overflow-y: visible;
          max-height: auto;
        }

        &__foot {
          position: static;
        }
      }
    }
  }
}

.loader {
  display: flex;
  height: 340px;

  &__mob {
    height: 280px;
  }
}

.empty {
  height: 280px;

  &__mob {
    height: 280px;
  }
}

.statistics__table {
  padding: 20px 12px 0;

  & table {
    width: 100%;
    font-size: 13px;
    text-align: center;

    & td:nth-child(2) {
      width: 23%;
    }

    & th:nth-child(2) {
      width: 23%;
    }

    & td,
    & th {
      width: 14%;
      padding: 12px 10px 0;
    }

    & td:first-child {
      width: 17.5%;
      text-align: left;
    }

    & th:first-child {
      width: 17.5%;
      text-align: left;
    }

    & td:last-child {
      width: 17.5%;
    }

    & th:last-child {
      width: 17.5%;
    }

    & thead {
      border-bottom: solid 3px #d9dade;

      & th {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 1em;
        font-weight: normal;
      }
    }

    & tbody {
      & td {
        padding: 12px 10px;
        font-size: 1.15em;
        vertical-align: middle;
      }

      & td:first-child {
        font-size: 1em;
        font-weight: 400;
      }

      & tr:nth-child(even) {
        background: #f8f8f8;
      }
    }

    & tfoot {
      font-weight: 700;

      & td {
        padding-top: 20px;
        font-size: 1.15em;
      }
    }
  }

  &__scroll {
    position: relative;
    max-height: 240px;

    &__mobile {
      max-height: 200px;
    }

    &__viewport {
      position: relative;

      overflow-y: scroll;

      width: 100%;
      height: 240px;
      max-height: 240px;

      &__mobile {
        height: 200px;
        max-height: 200px;
      }
    }
  }

  &__scroll::after {
    content: '';

    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 10px;

    background: linear-gradient(to top, rgb(0 0 0 / 6%), rgb(255 255 255 / 0%));
  }

  &__link {
    padding-bottom: 1px;
    color: #005cc3;
    border-bottom: dotted 1px;
    transition-duration: 0.2s;

    &:hover {
      color: #c01;
    }
  }

  & .package {
    font-size: 10px;
    font-weight: 400;
    color: #2d6fe3;
    text-transform: uppercase;

    &::before {
      @include icon_package;

      margin-right: 5px;
      font-size: 25px;
      color: #2d6fe3;
      vertical-align: middle;
    }

    &.turbo,
    &.turbo::before {
      color: #be3e55;
    }

    &.lite,
    &.lite::before {
      color: #ffe8be;
    }

    &.plus,
    &.plus::before {
      color: #e0e793;
    }
  }

  &__info__last {
    font-size: 12px;
    line-height: 15px;
  }

  &__info__total {
    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
  }
}

.statistics__table__mobile {
  position: relative;

  overflow-y: auto;

  height: 260px;
  max-height: 260px;
  padding: 0;

  background-color: #fff;

  & table {
    position: relative;

    overflow: auto;
    display: table;
    display: block;

    min-width: 518px;

    & tbody {
      & tr:nth-child(even) {
        background: #f8f8f8 !important;
      }
    }
  }
}

.statistics__stats__mobile {
  padding: 0;
}
