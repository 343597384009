@import "_generated-font-mixins.scss";
.response {
  width: 100%;
  margin-top: 15px;
  padding: 15px;

  background-color: #fafafa;
  border-radius: 4px;

  &__title {
    margin-bottom: 10px;

    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    color: #333;
  }

  &__buttons {
    display: flex;
    gap: 10px;
  }
}
