@import "_generated-font-mixins.scss";
.main {
  padding: 20px 0 0;

  &__promo {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    &__recommends {
      position: relative;

      width: 473px;
      margin-left: 13px;
      padding-top: 11px;

      border: 1px solid #d8d4bc;
      border-radius: 4px;

      &__title {
        position: absolute;
        top: -12px;
        right: 0;
        left: 0;

        font-weight: 700;
        text-align: center;

        & > span {
          padding: 0 5px;
          background: #fff;
        }
      }

      &__list {
        display: flex;
        flex-flow: row wrap;
        gap: 0 15px;

        width: 100%;
        padding: 18px 0 0 10px;
      }
    }
  }

  &__ads__wrapper {
    padding-top: 40px;
  }
}
