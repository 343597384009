@import "_generated-font-mixins.scss";
.empty {
  &__search {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    margin-top: 73px;
    padding-bottom: 50px;

    color: #999;

    &__title {
      margin-top: 15px;

      font-size: 18px;
      font-weight: 700;
      line-height: 22px;
      color: #000;
      text-align: center;
    }

    &__text {
      max-width: 300px;
      margin: 0 auto;
      margin-top: 10px;

      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: silver;
      text-align: center;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 52px;
      height: 52px;
      padding: 20px;

      background: #efefef;
      border-radius: 4px;

      &::after {
        @include icon-search;

        color: #c0c0c1;
      }
    }
  }
}
