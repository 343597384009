@import "_generated-font-mixins.scss";
.wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  color: #333;
  letter-spacing: -0.42px;

  &.mobile {
    & .price {
      font-size: 15px;
    }

    & .info {
      font-size: 15px;
      font-weight: 100;
    }
  }
}

.price {
  margin-right: 7px;

  font-size: 13px;
  font-weight: 700;
  line-height: 17px;
  color: #333;
  white-space: nowrap;

  &__thin {
    font-weight: 300;
  }
}

.first__payment {
  box-sizing: border-box;
  height: 19px;
  padding: 4px 8px;

  font-size: 10px;
  line-height: 11px;
  color: #0079ff;
  text-align: center;
  white-space: nowrap;

  background-color: #e7f2ff;
  border-radius: 2px;
}

.info {
  max-height: 14px;

  font-size: 12px;
  line-height: 14px;
  color: #b4b4b4;
  white-space: nowrap;

  &::before {
    content: '/\00a0';
    margin-right: 2px;
  }
}
