@import "_generated-font-mixins.scss";
.container {
  padding: 0 0 70px;
}

.profile {
  display: flex;
  margin-top: 20px;
}

.sidebar {
  width: 240px;
  margin-right: 20px;
}

.content {
  &__ads {
    position: relative;
    width: 740px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  &__data {
    margin-top: 20px;

    & > span {
      display: block;

      padding: 80px 0 100px;

      font-size: 17px;
      line-height: 20px;
      color: #999;
      text-align: center;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-bottom: 30px;

    & h3 {
      font-size: 17px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  &__categories {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 14px;
  }
}

.loading__ads {
  opacity: 0.5;
}

.category__select {
  position: absolute;
  top: 0;
  right: 0;
}
