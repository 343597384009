@import "_generated-font-mixins.scss";
.print {
  display: flex;
  gap: 11px;
  margin-bottom: 8px;
  color: #0058c5;

  &:hover {
    & .print__text {
      color: #c01;
    }
  }

  &__text {
    margin-left: -5px;
  }

  &__icon {
    &::before {
      @include icon-print-ad;

      position: relative;
      right: 1px;
      font-size: 18px;
      color: #999;
    }
  }
}
