@import "_generated-font-mixins.scss";
@mixin button-style {
  cursor: pointer;

  margin: 0;
  padding: 8px 10px;

  font-size: 13px;
  line-height: 1;
  color: #3560b9;

  background-color: #fff;
  outline: none;

  &:hover {
    background: #f5f6f7;
  }
}

.pagination {
  padding: 20px 0 18px;

  &__container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__buttons {
      &__wrapper {
        display: flex;
        clip-path: inset(0 round 2px);
        border: 1px solid #ccc;
        border-radius: 2px;

        &__icon {
          &__previous,
          &__next,
          &__first__page,
          &__last__page {
            @include button-style;

            width: 28px;
            border-right: 1px solid #ccc;

            &:last-child {
              border-right: none;
            }

            &.disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }
          }
        }

        &__button {
          @include button-style;

          border-right: 1px solid #ccc;

          &:first-child {
            border-radius: 2px 0 0 2px;
          }

          &:last-child {
            border-right: none;
            border-radius: 0 2px 2px 0;
          }

          &.active {
            position: relative;
            z-index: 1;

            margin: -1.1px;

            color: #fff;

            background-color: #3560b9;
            border: 1px solid #3560b9;
          }

          &:only-child {
            margin: -0.9px;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
