@import "_generated-font-mixins.scss";
.categories__filter {
  width: 260px;
  margin-top: 10px;
  padding-right: 20px;
}

.subcategory__content__filter {
  max-width: 240px;
}

.current__subcategory {
  margin-bottom: 12px;
  background: #f6f6f8;
  border-radius: 4px;

  &__title {
    padding: 15px;

    font-size: 15px;
    font-weight: 700;
    line-height: 17px;
    color: #333;
  }

  &__hr {
    height: 1px;
    margin: 0 15px;
    background: #e0e0e0;
    border: 0;
  }

  &__reset {
    cursor: pointer;

    position: relative;

    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    max-width: 239px;
    padding: 14px 14px 14px 15px;

    font-size: 13px;
    color: #999;

    &::before {
      @include icon-cancel;

      width: 10px;
      height: 10px;
      font-size: 10px;
      color: #999;
    }
  }
}

.filters__subcategory {
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}

.search__delivery {
  position: relative;

  margin-bottom: 12px;
  padding: 14px;

  border: 1px solid #f0f0f0;
  border-radius: 4px;

  &__filter {
    margin-bottom: 12px;
    padding: 14px;
    border: none;
    border-bottom: 1px solid #f0f0f0;
  }
}

.fake__filter {
  margin-bottom: 12px;
  padding: 14px;
}
