@import "_generated-font-mixins.scss";
.labels {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block: 10px;

  &.mobile {
    display: block;
    margin: 0;
    padding-top: 10px;

    & .labels__label {
      margin-right: 10px;
      padding: 6px 8px;

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      color: #0079ff;
      text-align: center;
      white-space: nowrap;

      background-color: #e7f2ff;
      border-radius: 2px;
    }
  }

  &__label {
    display: inline-block;

    margin-bottom: 10px;
    padding: 10px 15px;

    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: grey;
    letter-spacing: 0;

    background: #f2f2f2;
    border-radius: 3px;
  }
}
