@import "_generated-font-mixins.scss";
.profile {
  display: flex;
  flex-flow: column;
  align-items: center;

  box-sizing: border-box;
  width: 100%;
  min-width: 240px;
  margin-bottom: 10px;
  padding: 20px;

  text-align: center;

  border: 1px solid #eaeaea;
  border-radius: 4px;

  &__avatar {
    width: 105px;
    height: 105px;
    margin-bottom: 20px;
    text-align: center;

    & img {
      border-radius: 50%;
    }
  }

  &__login {
    display: flex;
    gap: 5px;
    justify-content: center;
    width: 100%;

    & span {
      overflow: hidden;

      font-size: 18px;
      font-weight: 700;
      line-height: 21px;
      text-overflow: ellipsis;
    }

    &__verified {
      color: #65922c;
    }
  }

  &__date {
    margin-bottom: 8px;

    &__number {
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 700;

      & span {
        font-weight: 400;
        opacity: 0.7;
      }
    }
  }

  &__text {
    width: 100%;
    font-size: 14px;
    line-height: 16px;
  }

  &__verified__icon {
    margin-top: -3px;

    &::after {
      @include icon-verified-user;

      font-size: 17px;
      color: #65922c;
    }
  }
}
