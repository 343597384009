@import "_generated-font-mixins.scss";
.wrapper {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: flex-end;

  &__change {
    cursor: pointer;

    position: relative;

    padding: 7px 10px 7px 37px;

    font-size: 13px;
    color: #fff;
    text-decoration: none;

    background-color: rgb(0 0 0 / 40%);
    border: none;
    border-radius: 4px;

    &::before {
      @include icon-camera;

      position: absolute;
      top: 3px;
      left: 10px;

      display: block;

      font-size: 17px;
      color: #fff;
    }
  }

  &:hover.position__top {
    padding-bottom: 30px;
  }

  &.position__top {
    top: 15px;
    right: 15px;
  }

  &:hover .dropdown,
  &__change:hover .dropdown {
    display: flex;
  }
}

.dropdown {
  position: absolute;
  z-index: 22;
  right: 0;

  display: none;
  flex-direction: column;
  gap: 8.2px;
  align-items: flex-start;

  min-width: 100%;
  margin-top: 10px;
  padding: 15px;

  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 5px 9px 0 rgb(0 0 0 / 23%);

  &__input {
    display: none;
  }

  &:hover {
    display: flex;
  }

  &__button {
    font-size: 13px;
    color: #333;

    &:hover {
      opacity: 0.6;
    }
  }
}

.position {
  &__top {
    top: 30px;
    right: 30px;
  }
}
