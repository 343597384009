@import "_generated-font-mixins.scss";
.add {
  padding-bottom: 30px;

  &__header {
    display: flex;
    gap: 10px;
    align-items: baseline;

    padding: 30px 0;

    font-weight: 700;
    line-height: 48px;

    &__title {
      font-size: 32px;
    }

    &__helpBtn {
      font-size: 13px;
      line-height: 1;
      color: #005cc3;
      border-bottom: 1px dotted;

      &:hover {
        color: #c01;
      }
    }
  }
}

.category {
  display: grid;
  grid-template-columns: 1fr 1fr;
  font-size: 15px;

  & li {
    margin-bottom: 10px;

    & span {
      &:hover {
        color: #005cc3;
        text-decoration: underline;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    height: 24px;
    color: #333;

    &:focus-visible {
      outline: 1px solid #005cc3;
    }

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;
      margin-right: 9px;

      font-size: 24px;
      color: #999;
    }
  }
}

.arrow__left {
  &::before {
    @include icon-arrow-left;

    font-size: 7px;
    color: #005cc3;
  }
}

.subcategory {
  font-size: 15px;

  &__count {
    display: inline-block;

    width: auto;
    padding-left: 5px;

    font-size: 15px;
    line-height: 17px;
    color: #999;
    text-align: right;
    white-space: nowrap;
  }

  &__list {
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: repeat(auto, 1fr);
    column-gap: 30px;
  }

  &__column {
    display: flex;
    flex-direction: column;

    & a {
      font-size: 15px;
      line-height: 2;

      &:hover {
        color: #005cc3;
        text-decoration: underline;
      }
    }
  }

  &__group {
    margin-bottom: 30px;
  }

  & li {
    list-style: none;
  }

  &__groupHeading {
    margin-bottom: 5px;
  }

  &__backLink {
    display: flex;
    gap: 5px;
    align-items: center;

    margin-bottom: 24px;

    font-size: 15px;
    line-height: 1.2;
    color: #005cc3;

    & > .arrow__left {
      margin-top: -2px;
    }

    &:hover {
      & > p {
        color: #005cc3;
        text-decoration: underline;
      }
    }
  }
}

.tooltip {
  width: 300px !important;
  padding: 10px;

  font-family: Arial, sans-serif;
  font-size: 13px;
  font-weight: normal;
  line-height: 15px;
  color: #fff;
}

.modal {
  overflow: hidden;
  width: 600px;
  max-height: 297px;

  &__title {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 23px;
  }

  &__description {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 17px;
  }
}

.autocomplete {
  &__search {
    position: relative;
    margin-bottom: 50px;
    padding-bottom: 120px;

    & ul {
      overflow: hidden;
      max-height: 100px;
      margin-top: 10px;
    }

    &__input {
      overflow: hidden;

      width: 100%;
      height: 30px;
      margin-bottom: 20px;
      padding-right: 0;
      padding-left: 33px;
      padding-block: 4px;

      font-size: 15px;
      line-height: 30px;

      border: 1px solid #ced3d3;
      border-radius: 4px;

      &:is(:focus, :focus-visible) {
        border: 1px solid #ced3d3;
      }

      &::placeholder {
        font-size: 15px;
        color: #aaa;
      }
    }

    &__wrapper {
      position: relative;
    }
  }

  &__suggestion {
    cursor: pointer;

    margin-bottom: 8px;
    padding: 5px 10px 6px;

    line-height: 1;
    color: #005cc3;

    background-color: rgb(0 107 184 / 10%);
    border-radius: 4px;

    &:hover {
      opacity: 0.85;
    }

    & a {
      display: block;
    }
  }
}

.hidden__input {
  pointer-events: none;
  position: absolute;
  z-index: -1;
  background-color: transparent;
}

.loading {
  color: #999;
}

.symlink {
  &::after {
    @include icon-symlink;

    display: inline-block;

    margin-left: 5px;

    font-size: 10px;
    color: #4c62ae;
    text-decoration: none;
  }
}

.container {
  width: 1000px;
  margin: 0 auto;
}
