@import "_generated-font-mixins.scss";
.breadcrumbs {
  display: flex;
  margin: 15px 0 0;
  font-size: 12px;

  &.mobile {
    & .breadcrumbs__item {
      margin-right: 13px;
      padding-right: 18px;
      color: #999;

      &:not(:last-child)::after {
        @include icon-bcrumb-separator;

        position: absolute;
        top: 0;
        right: 0;

        font-size: 10px;
        color: #999;
        vertical-align: middle;
      }

      &:last-child {
        pointer-events: all;
        cursor: default;
        color: #999;
      }
    }
  }

  &__item {
    position: relative;

    margin-right: 5px;
    padding-right: 10px;

    line-height: 14px;
    color: #999;

    & > a {
      &:hover {
        color: #005cc3;
      }
    }

    &:not(:last-child)::after {
      @include icon-bcrumb-separator;

      position: absolute;
      top: 0;
      right: 0;

      font-size: 10px;
      line-height: 14px;
      color: #999;
      vertical-align: middle;
    }

    &:last-child {
      pointer-events: none;
      cursor: default;

      overflow: hidden;

      max-width: 300px;

      color: #333;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & > span {
      margin-left: 5px;
    }
  }
}

.mobile {
  padding: 0 0 15px 10px;
}
