@import "_generated-font-mixins.scss";
.cabinet {
  position: relative;

  box-sizing: border-box;
  min-width: 40px;
  height: 80px;
  padding: 0 8px;

  text-align: center;
  vertical-align: middle;

  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;

  &__booster {
    &:hover {
      & .cabinet__booster__stopped {
        visibility: visible;
      }
    }

    &__stopped {
      position: absolute;
      top: 46px;
      left: -72px;
      transform: rotate(180deg);

      width: 186px;
      height: 74px;
      padding: 10px 0;

      font-size: 14px;
      color: #fff;

      visibility: hidden;
      background-color: #000;
      border-radius: 4px;

      & > div {
        cursor: pointer;
        margin-top: 10px;
        text-decoration: underline;
      }

      &__arrow {
        position: absolute;
        top: 58px;
        left: 88px;
        transform: rotate(45deg);

        width: 10px;
        height: 10px;

        background-color: #000;
      }
    }
  }

  &__dot {
    position: absolute;
    top: 4px;
    right: 3px;

    display: block;

    width: 7px;
    height: 7px;

    background-color: #aac544;
    border-radius: 50%;

    &.exceeded {
      background-color: #eded43;
    }
  }

  &__wrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;

    &:hover {
      opacity: 0.75;
    }

    &.blocked__cursor {
      cursor: not-allowed;
    }
  }

  &__icon__booster {
    cursor: pointer;
    width: 100%;

    &.blocked {
      cursor: not-allowed;
      opacity: 0.4;
      filter: brightness(0);
    }

    &::after {
      @include icon-booster;

      font-size: 30px;
      color: #035db8;
    }
  }

  &__icon__booster__blocked {
    pointer-events: none;
    cursor: pointer;

    width: 100%;

    opacity: 0.4;
    filter: brightness(0);

    &::after {
      @include icon-booster;

      font-size: 30px;
      color: #035db8;
    }
  }
}

.booster__reset {
  background-color: transparent;
}

.booster {
  z-index: 4 !important;

  margin-top: -8px !important;
  padding: 0 !important;

  opacity: 1 !important;
  border-radius: 4px !important;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 5%) !important;
}

.booster__text {
  max-width: 250px;

  font-size: 15px;
  line-height: 18px;
  color: white;
  text-align: center;
  word-break: break-word;

  &.unspecified {
    max-width: none;
    padding: 20px 20px 5px;
    color: #000;
  }
}

.booster__image {
  width: 100%;
  margin-top: 13px;
  padding: 15px 10px;

  background: #f0f1f4;
  border-radius: 0 0 4px 4px;
}

.booster__link {
  margin-top: 40px;
  font-size: 14px;
  color: #035db8;

  &:hover {
    color: #c01;
  }
}

.underline {
  cursor: pointer;

  margin-top: 10px;

  font-size: 15px;
  line-height: 18px;
  color: white;
  text-decoration: underline;
}

.stopped {
  transform: rotate(180deg);
  opacity: 0.5;
}

.tooltip {
  z-index: 4 !important;
  background-color: #000;
}
