@import "_generated-font-mixins.scss";
.complain {
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  color: #0058c5;

  &:hover {
    & .complain__text {
      color: #c01;
    }
  }
}

.dislike {
  &::before {
    @include icon-like;

    font-size: 15px;
    color: #999;
  }
}
