@import "_generated-font-mixins.scss";
.reactions {
  display: flex;
  gap: 15px;

  & .like {
    display: flex;
    font-size: 12px;
    color: #427606;

    &__transform {
      position: relative;
      transform: scale(1, -1);
      width: 22px;
      height: 17px;
    }

    &__icon {
      &::after {
        @include icon-like;

        font-size: 17px;
        color: #999;
      }
    }

    &__icon__fill {
      &::after {
        @include icon-like;

        font-size: 17px;
        color: #427606;
      }
    }
  }

  & .dislike {
    display: flex;
    font-size: 12px;
    color: #ef4926;

    &__transform {
      position: relative;
      width: 22px;
      height: 17px;
    }

    &__icon {
      &::after {
        @include icon-like;

        position: absolute;
        top: -2px;
        left: 0;

        font-size: 17px;
        color: #999;
      }
    }

    &__icon__fill {
      &::after {
        @include icon-like;

        position: absolute;
        top: -2px;
        left: 0;

        font-size: 17px;
        color: #ef4926;
      }
    }
  }
}

.hover {
  &:hover {
    opacity: 0.7;
  }
}
